import dayjs from "dayjs"
import _ from "lodash"
import { CSVOption } from "../../../adult/types/nhif.types"
import {
  MEDICAL_COLLABORATION_SYSTEM,
  MEDICAL_COLLABORATION_SYSTEM_FOR_TRIAL_AND_SPECIAL_VISIT,
} from "../../../adult/constants"
interface MEAL_PROVISION_INTERFACE {
  label: string
  value: any
}

export const GenderObj = {
  M: "Male",
  F: "Female",
}
export const USER_ATTENDANCE_VALUES = {
  UNREGISTERED: "0",
  ATTENDANCE: "1",
  ABSENCE: "2",
  VISIT_SPECIAL_SUPPORT: "3",
  TRIAL_SUPPORT: "4",
  OFFSITE_WORK: "5",
  OFFSITE_SUPPORT: "6",
  ABSENCE_NO_ADDITION: "7",
  TRANSITION_PREPARATION: "8",
}

export const ATTENDANCE_BASED_NHIF_REMARKS = {
  [USER_ATTENDANCE_VALUES.OFFSITE_WORK]: "施設外就労",
  [USER_ATTENDANCE_VALUES.OFFSITE_SUPPORT]: "日報有り",
  [USER_ATTENDANCE_VALUES.TRANSITION_PREPARATION]: "日報有り",
}

export const ATTENDANCE_WITH_DISABLED_TRANSPORTATION = [
  USER_ATTENDANCE_VALUES.VISIT_SPECIAL_SUPPORT,
  USER_ATTENDANCE_VALUES.TRIAL_SUPPORT,
  USER_ATTENDANCE_VALUES.ABSENCE,
  USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION,
]

export const ATTENDANCE_STATUS_TYPE = [
  {
    label: "Attendance  ",
    value: USER_ATTENDANCE_VALUES.ATTENDANCE,
  },
  {
    label: "Absence",
    value: USER_ATTENDANCE_VALUES.ABSENCE,
  },
  {
    label: `Absent (no extra charge for absent)`,
    value: USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION,
  },
]

export const WORK_TYPES = [
  {
    label: "Full time",
    value: 1,
  },
  {
    label: "Full-time/part-time",
    value: 2,
  },
  {
    label: "Part-time/full-time",
    value: 3,
  },
  {
    label: "Part time",
    value: 4,
  },
]
export const DEV_ENVIRONMENTS = ["development", "local"]

export const GRADES = [
  {
    label: "一級地",
    value: "1",
  },
  {
    label: "二級地",
    value: "2",
  },
  {
    label: "三級地",
    value: "3",
  },
  {
    label: "四級地",
    value: "4",
  },
  {
    label: "五級地",
    value: "5",
  },
  {
    label: "六級地",
    value: "6",
  },
  {
    label: "七級地",
    value: "7",
  },
  {
    label: "その他",
    value: "Others",
  },
]

export const ACCOUNT_TYPE_OPTIONS = [
  {
    label: "---",
    value: 0,
  },
  {
    label: "Current",
    value: 1,
  },
  {
    label: "Normal",
    value: 2,
  },
  {
    label: "Savings",
    value: 3,
  },
  {
    label: "Others",
    value: 4,
  },
]
export const PREFECTURES = [
  {
    label: "---",
    value: "徳島県",
  },
  {
    label: "東京都",
    value: "東京都",
  },
  {
    label: "北海道",
    value: "北海道",
  },
  {
    label: "青森県",
    value: "青森県",
  },
  {
    label: "岩手県",
    value: "岩手県",
  },
  {
    label: "宮城県",
    value: "宮城県",
  },
  {
    label: "秋田県",
    value: "秋田県",
  },
  {
    label: "山形県",
    value: "山形県",
  },
  {
    label: "福島県",
    value: "福島県",
  },
  {
    label: "茨城県",
    value: "茨城県",
  },
  {
    label: "栃木県",
    value: "栃木県",
  },
  {
    label: "群馬県",
    value: "群馬県",
  },
  {
    label: "埼玉県",
    value: "埼玉県",
  },
  {
    label: "千葉県",
    value: "千葉県",
  },
  {
    label: "神奈川県",
    value: "神奈川県",
  },
  {
    label: "新潟県",
    value: "新潟県",
  },
  {
    label: "富山県",
    value: "富山県",
  },
  {
    label: "石川県",
    value: "石川県",
  },
  {
    label: "福井県",
    value: "福井県",
  },
  {
    label: "山梨県",
    value: "山梨県",
  },
  {
    label: "長野県",
    value: "長野県",
  },
  {
    label: "岐阜県",
    value: "岐阜県",
  },
  {
    label: "静岡県",
    value: "静岡県",
  },
  {
    label: "愛知県",
    value: "愛知県",
  },
  {
    label: "三重県",
    value: "三重県",
  },
  {
    label: "滋賀県",
    value: "滋賀県",
  },
  {
    label: "京都府",
    value: "京都府",
  },
  {
    label: "大阪府",
    value: "大阪府",
  },
  {
    label: "兵庫県",
    value: "兵庫県",
  },
  {
    label: "奈良県",
    value: "奈良県",
  },
  {
    label: "和歌山県",
    value: "和歌山県",
  },
  {
    label: "鳥取県",
    value: "鳥取県",
  },
  {
    label: "島根県",
    value: "島根県",
  },
  {
    label: "岡山県",
    value: "岡山県",
  },
  {
    label: "広島県",
    value: "広島県",
  },
  {
    label: "山口県",
    value: "山口県",
  },
  {
    label: "徳島県",
    value: "徳島県",
  },
  {
    label: "香川県",
    value: "香川県",
  },
  {
    label: "愛媛県",
    value: "愛媛県",
  },
  {
    label: "高知県",
    value: "高知県",
  },
  {
    label: "福岡県",
    value: "福岡県",
  },
  {
    label: "佐賀県",
    value: "佐賀県",
  },
  {
    label: "長崎県",
    value: "長崎県",
  },
  {
    label: "熊本県",
    value: "熊本県",
  },
  {
    label: "大分県",
    value: "大分県",
  },
  {
    label: "宮崎県",
    value: "宮崎県",
  },
  {
    label: "鹿児島県",
    value: "鹿児島県",
  },
  {
    label: "沖縄県",
    value: "沖縄県",
  },
]
export const MEAL_PROVISION: MEAL_PROVISION_INTERFACE[] = [
  { label: "Providing", value: true },
  { label: "Not Providing", value: false },
]

export const PREFECTURE_OPTIONS = PREFECTURES.map((item, index) => {
  return {
    label: item.label,
    value: index + 1,
  }
})

export const MunicipalRadio = [
  { label: "Truncation", value: 0 },
  { label: "Rounded up", value: 1 },
  { label: "Round off", value: 2 },
]

export const AUTO_EMAIL_SENDING_OPTIONS = [
  {
    label: "Attended notification",
    value: 1,
  },
  {
    label: "Leave notification",
    value: 2,
  },
  {
    label: "Guidance of My Page password reissue form",
    value: 3,
  },
  {
    label: "Notice of completion of password change on My Page",
    value: 4,
  },
]

// Fetching Days of Current Months
const getCurrentMonthDays = () => {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()
  const daysInMonth = new Date(
    currentDate.getFullYear(),
    currentMonth + 1,
    0,
  ).getDate()
  return { daysInMonth }
}
const { daysInMonth } = getCurrentMonthDays()
export const GET_DAY_OPTIONS = Array.from(
  { length: daysInMonth },
  (_, date) => ({ label: `${date + 1}日`, value: `${date + 1}` }),
)

export const SERVICE_TYPE_VALUES = {
  TYPE_1: 1,
  TYPE_2: 2,
  TRANSITION: 3,
}

export const FACILITY_TYPES = [
  { label: "Employment Type A", value: SERVICE_TYPE_VALUES.TYPE_1 },
  { label: "Employment Type B", value: SERVICE_TYPE_VALUES.TYPE_2 },
  {
    label: "Employment Transition Support",
    value: SERVICE_TYPE_VALUES.TRANSITION,
  },
]

export const WORK_TYPE = {
  [SERVICE_TYPE_VALUES.TYPE_1]: "Working type A",
  [SERVICE_TYPE_VALUES.TYPE_2]: "Working type B",
  [SERVICE_TYPE_VALUES.TRANSITION]: "Employment Transition Support",
}

export const FACILITY_SERVICE_TYPES = {
  [SERVICE_TYPE_VALUES.TYPE_1]: "Employment Type A",
  [SERVICE_TYPE_VALUES.TYPE_2]: "Employment Type B",
  [SERVICE_TYPE_VALUES.TRANSITION]: "Employment Transition Support",
}
export const FACILITY_SERVICE_TYPES_V2 = {
  [SERVICE_TYPE_VALUES.TYPE_1]: "Employment continuation support type A",
  [SERVICE_TYPE_VALUES.TYPE_2]: "Employment continuation support type B",
  [SERVICE_TYPE_VALUES.TRANSITION]: "Employment Transition Support",
}
export const GetUpToFiveYears = (currentYear) => {
  const endYear = currentYear + 5
  const yearOptions = []
  for (let year = currentYear; year <= endYear; year++) {
    yearOptions.push({ label: year, value: year })
  }
  return yearOptions
}

export const MonthsDropDown = () => {
  const monthOptions = []
  for (let month = 1; month <= 12; month++) {
    const monthValue = month.toString()
    const monthLabel = monthValue
    monthOptions.push({ label: monthLabel, value: monthValue })
  }

  return monthOptions
}
export const MINUTES_TIMES = (() => {
  const result = []
  result.push({ label: "--", value: "" })
  for (let i = 0; i < 60; i++) {
    if (i < 10) {
      result.push({
        label: `0${i}`,
        value: "0" + i,
      })
    } else {
      result.push({
        label: i,
        value: i?.toString(),
      })
    }
  }
  return result
})()
export const MINUTES_HOUR = (() => {
  const result = []
  result.push({ label: "--", value: "" })
  for (let i = 0; i < 24; i++) {
    if (i < 10) {
      result.push({
        label: `0${i}`,
        value: "0" + i,
      })
    } else {
      result.push({
        label: i,
        value: i?.toString(),
      })
    }
  }
  return result
})()

export const STAFF_PRESENT = 1
export const STAFF_ABSENT = 2
export const getFacilityNameFromId = (allFacilities, facilityId) => {
  let _name = ""
  allFacilities.forEach((facility) => {
    if (facility.value == facilityId) {
      _name = facility.label
    }
  })
  return _name
}

export const USER_ABSENCE_TYPES = [
  { label: "Addition for Absence(Ⅰ)", value: +USER_ATTENDANCE_VALUES.ABSENCE },
  {
    label: "Addition for Absence(Ⅰ)【No addition】",
    value: +USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION,
  },
]

export const PAZE_SIZE_OPTIONS = [
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "75", value: 75 },
  { label: "100", value: 100 },
]

export const pickupAndDropUpDummyList = (
  currentYearAndMonth,
  setDaysOfMonth,
) => {
  const yearMonth = dayjs(currentYearAndMonth)
  const days = []
  const daysInMonth = yearMonth.daysInMonth()
  for (let day = 1; day <= daysInMonth; day++) {
    const date = yearMonth.date(day)
    const dayOfWeekNumber = date.day()
    const dayOfWeekAbbreviation = [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat",
    ][dayOfWeekNumber]
    let holidayFlag = 0
    if (dayOfWeekNumber === 0 || dayOfWeekNumber === 6) {
      holidayFlag = 1
    }
    days.push({
      day: day.toString(),
      dayOfWeek: dayOfWeekAbbreviation,
      holiday_flag: holidayFlag,
    })
  }

  setDaysOfMonth(days)
}

export const LAST_EMP_STATUS_OF_USER = [
  { label: "Currently employed", value: 1 },
  { label: "Currently employed", value: 2 },
  { label: "Not employed, searching for job", value: 3 },
  { label: "Other", value: 3 },
]

export const PLACE_OF_EMPLOYMENT = [
  { label: "No Change", value: 1 },
  { label: "Register  Place of employment", value: 2 },
]

export const LAST_STATUS_OF_USER = [
  {
    label:
      "Working (up to one job change within one month after leaving the job)",
    value: 1,
  },
  {
    label:
      "Working (with 1 job change after being unemployed for more than a month, or with 2 or more job changes)",
    value: 2,
  },
  { label: "Not working", value: 3 },
  { label: "Other", value: 4 },
]

export const TWELVE_DIFFERENT_COLORS = [
  "#4AB2C4",
  "#C5B47E",
  "#829457",
  "#953479",
  "#D8B83E",
  "#0285CB",
  "#E9A228",
  "#589475",
  "#948B12",
  "#4B5CE4",
  "#FF5800",
  "#C647A3",
]

export const USER_TYPE_VALUES = {
  STAFF: "0",
  ADMIN: "1",
}

export const USER_TYPES = [
  { label: "Staff", value: USER_TYPE_VALUES.STAFF },
  { label: "System Administrator", value: USER_TYPE_VALUES.ADMIN },
]

export type PERMISSION_TYPE = "0" | "1" | "2"

interface IPermissionValue {
  [key: string]: PERMISSION_TYPE
}

export const PERMISSION_VALUES: IPermissionValue = {
  DONT_DISPLAY: "0",
  BROWSE: "1",
  BROWSE_EDIT: "2",
}

export const getDaysOfMont = (year, month) => {
  const startDate = new Date(year, month - 1, 1)
  const endDate = new Date(year, month, 0)

  const fromDate = `${startDate.getFullYear()}年${(startDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}月${startDate.getDate().toString().padStart(2, "0")}日`
  const toDate = `${endDate.getFullYear()}年${(endDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}月${endDate.getDate().toString().padStart(2, "0")}日`

  return { from_date: fromDate, to_date: toDate }
}

export const userWageSetting = (
  user_wage_setting: any,
  facility_wage_settings: any,
  user_facility_wage_settings: any,
  non_zero_facility_allowance?: boolean,
  non_zero_Facility_deduction?: boolean,
) => {
  const allowanceItems = facility_wage_settings?.filter(
    (item) =>
      (item.facility_wage_setting?.item_type === "allowance" ||
        item?.item_type === "allowance") &&
      (!non_zero_facility_allowance ||
        item?.amount != 0 ||
        item?.skip_non_zero_check),
  )

  Object.keys(allowanceItems || {}).forEach((key) => {
    if (allowanceItems[key]?.facility_id > 0)
      allowanceItems[key].amount =
        user_facility_wage_settings?.find(
          (user_facility_wage_setting) =>
            user_facility_wage_setting.facility_wage_setting_id ===
            allowanceItems[key].id,
        )?.amount || 0
  })

  const deductionItems = facility_wage_settings?.filter(
    (item) =>
      (item.facility_wage_setting?.item_type === "deduction" ||
        item?.item_type === "deduction") &&
      (!non_zero_Facility_deduction || item?.amount != 0),
  )

  Object.keys(deductionItems || {}).forEach((key) => {
    if (deductionItems[key]?.facility_id > 0)
      deductionItems[key].amount =
        user_facility_wage_settings?.find(
          (user_facility_wage_setting) =>
            user_facility_wage_setting.facility_wage_setting_id ===
            deductionItems[key].id,
        )?.amount || 0
  })

  const allowanceAllItems = _.concat(
    allowanceItems,
    user_wage_setting?.filter((item) => item.item_type === "allowance"),
  )
  const deductionAllItems = _.concat(
    deductionItems,
    user_wage_setting?.filter((item) => item.item_type === "deduction"),
  )

  const totalAllowanceAmount = allowanceAllItems?.reduce(
    (total, item) => total + (item?.amount || 0),
    0,
  )
  const totalDeductionAmount = deductionAllItems?.reduce(
    (total, item) => total + (item?.amount || 0),
    0,
  )
  const userAllowanceItems = user_wage_setting?.filter(
    (item) => item?.item_type === "allowance",
  )
  const userDeductionItems = user_wage_setting?.filter(
    (item) => item?.item_type === "deduction",
  )
  const totalAmount = totalAllowanceAmount - totalDeductionAmount
  return {
    allowanceAllItems,
    deductionAllItems,
    totalAllowanceAmount,
    totalDeductionAmount,
    userAllowanceItems,
    userDeductionItems,
    totalAmount,
  }
}

export const getUserAttendanceTypesByService = (service: number) => {
  const USER_ATTENDANCE = {
    value: USER_ATTENDANCE_VALUES.ATTENDANCE,
    label: "Attendance2",
  }
  const USER_ABSENT = {
    value: USER_ATTENDANCE_VALUES.ABSENCE,
    label: "Absent supported",
  }
  const USER_VISIT_SPECIAL_SUPPORT = {
    value: USER_ATTENDANCE_VALUES.VISIT_SPECIAL_SUPPORT,
    label: "Visit special support",
  }
  const USER_TRIAL_USE_SUPPORT = {
    value: USER_ATTENDANCE_VALUES.TRIAL_SUPPORT,
    label: "Trial use support",
  }
  const USER_OFFSITE_WORK = {
    value: USER_ATTENDANCE_VALUES.OFFSITE_WORK,
    label: "Off-site work",
  }
  const USER_OFFSITE_SUPPORT = {
    value: USER_ATTENDANCE_VALUES.OFFSITE_SUPPORT,
    label: "Off-site support",
  }
  const USER_ABSENT_NO_ADDITION = {
    value: USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION,
    label: "Absence (No addition)",
  }
  const USER_TRANSITION_PREPARATION = {
    value: USER_ATTENDANCE_VALUES.TRANSITION_PREPARATION,
    label: "Transition preparation support",
  }

  let attendanceTypes = []

  switch (service) {
    case 1:
      // for employment A
      attendanceTypes = [
        USER_ATTENDANCE,
        USER_ABSENT,
        USER_ABSENT_NO_ADDITION,
        USER_VISIT_SPECIAL_SUPPORT,
        USER_TRIAL_USE_SUPPORT,
        USER_OFFSITE_SUPPORT,
        USER_OFFSITE_WORK,
      ]
      break
    case 2:
      // for employment B
      attendanceTypes = [
        USER_ATTENDANCE,
        USER_ABSENT,
        USER_ABSENT_NO_ADDITION,
        USER_VISIT_SPECIAL_SUPPORT,
        USER_TRIAL_USE_SUPPORT,
        USER_OFFSITE_SUPPORT,
        USER_OFFSITE_WORK,
      ]
      break
    case 3:
      // for employment transition
      attendanceTypes = [
        USER_ATTENDANCE,
        USER_ABSENT,
        USER_ABSENT_NO_ADDITION,
        USER_VISIT_SPECIAL_SUPPORT,
        USER_TRIAL_USE_SUPPORT,
        USER_TRANSITION_PREPARATION,
      ]
      break
    default:
      attendanceTypes = []
  }

  return attendanceTypes
}
export const getNextFiscalYear = (dateString) => {
  // Extract the year and month from the provided date string (format: YYYY-MM)
  const [year, month] = dateString.split("-").map(Number)

  // If the month is from April (4) to December (12), the next fiscal year is the current year + 1
  if (month >= 4) {
    return year + 1
  }
  // If the month is from January (1) to March (3), the next fiscal year is the current year
  return year
}
const generateMonthsArray = (year: number, isCheck: boolean) => {
  const monthsArray = []
  if (isCheck) {
    // Generate months for Japanese fiscal year (April 1 - March 31)
    for (let i = 4; i <= 12; i++) {
      monthsArray.push(`${year}-${i < 10 ? "0" + i : i}`)
    }
    for (let i = 1; i <= 3; i++) {
      monthsArray.push(`${+year + 1}-${i < 10 ? "0" + i : i}`)
    }
  } else {
    // Generate months for the calendar year (January 1 - December 31)
    for (let i = 1; i <= 12; i++) {
      monthsArray.push(`${year}-${i < 10 ? "0" + i : i}`)
    }
  }
  return monthsArray
}

export const fillMissingMonths = (
  data: any[],
  year: number,
  isCheck?: boolean,
) => {
  const monthsArray = generateMonthsArray(year, isCheck)
  const filledData = monthsArray.map((month) => {
    const foundData = data.find(
      (item) =>
        item.year + "-" + item.month.toString().padStart(2, "0") === month,
    )
    return foundData
      ? { ...foundData, date: month }
      : {
          date: month,
          total_amount_paid: 0,
        }
  })
  return filledData
}

export const getTotalAmount = (arrayOne, arrayTwo) => {
  const arrayOneTotal = arrayOne?.length
    ? arrayOne.reduce(
        (totalAmount, item) =>
          totalAmount + parseFloat(item?.amount ? item?.amount : 0),
        0,
      )
    : []

  const arrayTwoTotal = arrayTwo?.length
    ? arrayTwo.reduce(
        (totalAmount, item) =>
          totalAmount + parseInt(item?.amount ? item?.amount : 0),
        0,
      )
    : []

  const totalAmount = arrayOneTotal - arrayTwoTotal
  return { totalAmount }
}
export const calculateDateDifference = (selectedDays) => {
  if (selectedDays) {
    const currentDate = dayjs()
    const selectedDateObj = dayjs(selectedDays)
    const differenceInDays = selectedDateObj.diff(currentDate, "day")
    return Math.abs(differenceInDays || 0)
  }
  return 0
}

export const calculateDaysFromEmployment = (selectedDays, retirementDate) => {
  if (selectedDays) {
    const currentDate = retirementDate ? dayjs(retirementDate) : dayjs()
    const selectedDateObj = dayjs(selectedDays)
    const differenceInDays = selectedDateObj.diff(currentDate, "day")
    return Math.abs(differenceInDays || 0)
  }
  return 0
}

// Function to convert Western year to Japanese era
export const getJapaneseEra = (year) => {
  if (year >= 1989) {
    return "平成" + (year - 1988)
  } else if (year >= 1926) {
    return "昭和" + (year - 1925)
  } else if (year >= 1912) {
    return "大正" + (year - 1911)
  }
  return "明治" + (year - 1867)
}

export const buttonsArray = [
  { name: "Facility", className: "facility", id: 1 },
  { name: "山武", className: "second", id: 2 },
  { name: "東松山", className: "third", id: 3 },
  { name: "(放) 宮子", className: "four", id: 4 },
  { name: "(放) 小泉", className: "five", id: 5 },
  { name: "本庄", className: "six", id: 6 },
  { name: "吉岡", className: "seven", id: 7 },
  { name: "熊谷", className: "eight", id: 8 },
  { name: "太田", className: "nine", id: 9 },
  { name: "宮子", className: "teen", id: 10 },
]

export const getOthersCheckBoxItems = (
  type: number,
  systemStatusSetting?: { compensation_system?: string },
) => {
  switch (type) {
    case SERVICE_TYPE_VALUES.TYPE_1:
      return [
        {
          value: ADDITIONAL_ITEM_VALUES.SUPPORT_FOR_LIVING_AT_HOME,
          label: "Support for living at home",
        },
        {
          value: ADDITIONAL_ITEM_VALUES.SOCIAL_LIFE_SUPPORT_SPECIAL,
          label: "Social life support",
        },
        {
          value: ADDITIONAL_ITEM_VALUES.INTENSIVE_SUPPORT,
          label: "Intensive support addition",
        },
        {
          value: ADDITIONAL_ITEM_VALUES.EMERGENCY_ACCEPTANCE,
          label: "Emergency acceptance addition",
        },
      ]
    case SERVICE_TYPE_VALUES.TYPE_2: {
      const generalOtherOptions = [
        {
          value: ADDITIONAL_ITEM_VALUES.SUPPORT_FOR_LIVING_AT_HOME,
          label: "Support for living at home",
        },
        {
          value: ADDITIONAL_ITEM_VALUES.SOCIAL_LIFE_SUPPORT_SPECIAL,
          label: "Social life support",
        },
        {
          value: ADDITIONAL_ITEM_VALUES.INTENSIVE_SUPPORT,
          label: "Intensive support addition",
        },
        {
          value: ADDITIONAL_ITEM_VALUES.EMERGENCY_ACCEPTANCE,
          label: "Emergency acceptance addition",
        },
      ]
      const optionsWhenCompensationSystemIsUniform = [
        {
          value: ADDITIONAL_ITEM_VALUES.REGIONAL_COLLABORATION_ADDITION,
          label: "Regional collaboration",
        },
        {
          value: ADDITIONAL_ITEM_VALUES.PEER_SUPPORT,
          label: "Peer support",
        },
      ]
      return systemStatusSetting?.compensation_system == "2"
        ? [...generalOtherOptions, ...optionsWhenCompensationSystemIsUniform]
        : generalOtherOptions
    }
    case SERVICE_TYPE_VALUES.TRANSITION:
      return [
        {
          value: ADDITIONAL_ITEM_VALUES.SUPPORT_FOR_LIVING_AT_HOME,
          label: "Support for living at home",
        },
        {
          value: ADDITIONAL_ITEM_VALUES.SOCIAL_LIFE_SUPPORT_SPECIAL,
          label: "Social life support",
        },
        {
          value: ADDITIONAL_ITEM_VALUES.INTENSIVE_SUPPORT,
          label: "Intensive support addition",
        },
        {
          value: ADDITIONAL_ITEM_VALUES.EMERGENCY_ACCEPTANCE,
          label: "Emergency acceptance addition",
        },
        {
          value: ADDITIONAL_ITEM_VALUES.COMMUTING_TRAINING,
          label: "Commuting training",
        },
      ]
    default:
      null
  }
}

export const ADDITIONAL_ITEM_VALUES = {
  VISIT_SPECIAL_SUPPORT: "VISIT_SPECIAL_SUPPORT",
  TRIAL_USE_SUPPORT: "TRIAL_USE_SUPPORT",
  ABSENCE_ADDITION: "ABSENCE_ADDITION",
  SOCIAL_LIFE_SUPPORT_SPECIAL: "SOCIAL_LIFE_SUPPORT_SPECIAL",
  SUPPORT_FOR_LIVING_AT_HOME: "SUPPORT_FOR_LIVING_AT_HOME",
  MEDICAL_COOPERATION_SYSTEM: "MEDICAL_COOPERATION_SYSTEM",
  EMPLOYMENT_TRANSITION_COOPERATION: "EMPLOYMENT_TRANSITION_COOPERATION",
  USER_BURDEN_LIMIT_MANAGEMENT: "USER_BURDEN_LIMIT_MANAGEMENT",
  INITIAL_ADDITION: "INITIAL_ADDITION",
  PHYSICAL_RESTRAINT_ABOLITION: "PHYSICAL_RESTRAINT_ABOLITION",
  REGIONAL_COLLABORATION_ADDITION: "REGIONAL_COLLABORATION_ADDITION",
  REGIONAL_COLLABORATION_MEETING_IMPLEMENTATION:
    "REGIONAL_COLLABORATION_MEETING_IMPLEMENTATION",
  PEER_SUPPORT: "PEER_SUPPORT",
  TRANSITION_PREPARATION_SUPPORT: "TRANSITION_PREPARATION_SUPPORT",
  COMMUTING_TRAINING: "COMMUTING_TRAINING",
  MENTALLY_DISABLED_DISCHARGE_SUPPORT: "MENTALLY_DISABLED_DISCHARGE_SUPPORT",
  INTENSIVE_SUPPORT: "INTENSIVE_SUPPORT",
  EMERGENCY_ACCEPTANCE: "EMERGENCY_ACCEPTANCE",
}

const ATTENDANCE_ADDITIONAL_ITEMS_DISABLE_ENABLE_BASED_ON_REMARKS: {
  [x: string]: {
    disable: Array<string> | boolean
    enable?: Array<string> | boolean
  }
} = {
  [USER_ATTENDANCE_VALUES.VISIT_SPECIAL_SUPPORT]: {
    disable: true,
    enable: [ADDITIONAL_ITEM_VALUES.EMERGENCY_ACCEPTANCE],
  },
  [USER_ATTENDANCE_VALUES.TRIAL_SUPPORT]: {
    disable: true,
  },
  [USER_ATTENDANCE_VALUES.OFFSITE_SUPPORT]: {
    disable: [ADDITIONAL_ITEM_VALUES.INTENSIVE_SUPPORT],
  },
  [USER_ATTENDANCE_VALUES.OFFSITE_WORK]: {
    disable: [ADDITIONAL_ITEM_VALUES.INTENSIVE_SUPPORT],
  },
  [USER_ATTENDANCE_VALUES.TRANSITION_PREPARATION]: {
    disable: [ADDITIONAL_ITEM_VALUES.INTENSIVE_SUPPORT],
  },
}

export const getOthersCheckBoxItemsWithAttendanceDisableCondition = (
  type: number,
  systemStatusSetting?: { compensation_system?: string },
  attendace_type?: string,
) => {
  const items = getOthersCheckBoxItems(type, systemStatusSetting)
  const conditionsBasedOnAttendance =
    ATTENDANCE_ADDITIONAL_ITEMS_DISABLE_ENABLE_BASED_ON_REMARKS?.[
      attendace_type
    ]
  if (!conditionsBasedOnAttendance) {
    // no condition return items
  }

  function shouldAdditionalItemBeEnabledOrDisabled(additionalItem) {
    let disabled = false
    if (
      typeof conditionsBasedOnAttendance?.enable == "boolean" &&
      conditionsBasedOnAttendance?.enable
    ) {
      // type of enable is boolean & true then all enabled
      disabled = false
    } else if (
      Array.isArray(conditionsBasedOnAttendance?.enable) &&
      conditionsBasedOnAttendance.enable.includes(additionalItem)
    ) {
      // if enable condition is array and specific additional item is set
      disabled = false
    } else if (
      typeof conditionsBasedOnAttendance?.disable == "boolean" &&
      conditionsBasedOnAttendance?.disable
    ) {
      // type of disabled is boolean & true then all disabled
      disabled = true
    } else if (
      Array.isArray(conditionsBasedOnAttendance?.disable) &&
      conditionsBasedOnAttendance.disable.includes(additionalItem)
    ) {
      // if disable condition is array and specific additional item is set
      disabled = true
    }
    return disabled
  }

  return items.map((item) => ({
    ...item,
    disabled: shouldAdditionalItemBeEnabledOrDisabled(item.value),
  }))
}

export const getMedicalCollaborationOptionsByAttendanceType = (
  attendanceType: string,
) => {
  const dashOpt = { label: "--", value: null, disabled: false }

  if (
    [
      USER_ATTENDANCE_VALUES.TRIAL_SUPPORT,
      USER_ATTENDANCE_VALUES.VISIT_SPECIAL_SUPPORT,
    ].includes(attendanceType)
  ) {
    return [
      dashOpt,
      ...MEDICAL_COLLABORATION_SYSTEM_FOR_TRIAL_AND_SPECIAL_VISIT,
    ]
  }
  return [dashOpt, ...MEDICAL_COLLABORATION_SYSTEM]
}

export const WAGE_OPTION_VALUES = {
  HOUR: "H",
  DAILY: "D",
  MONTHLY: "M",
  PIECE: "P",
}

export const WAGE_OPTIONS = [
  { label: "Hourly wage", value: WAGE_OPTION_VALUES.HOUR },
  { label: "Daily wage", value: WAGE_OPTION_VALUES.DAILY },
  { label: "Monthly wage", value: WAGE_OPTION_VALUES.MONTHLY },
  { label: "Piece Work Rate", value: WAGE_OPTION_VALUES.PIECE },
]

export const MAX_TRIAL_USE_SUPPORT_COUNT_MONTH = 15
export const MAX_INTENSIVE_SUPPORT_COUNT_MONTH = 4
export const MAX_INTENSIVE_SUPPORT_USE_PERIOD = 90

export const displayLabelForTrialUseSupportInServiceProvisionRecord = (
  trialUseSupportTillDate: number,
) => {
  if (trialUseSupportTillDate <= 5) return "1"
  else if (trialUseSupportTillDate <= MAX_TRIAL_USE_SUPPORT_COUNT_MONTH)
    return "2"
  return ""
}

export const displayLabelForTrialUseSupportInAttendance = (
  trialUseSupportTillDate: number,
) => {
  if (trialUseSupportTillDate <= 5)
    return "Experience support for disability welfare services (I)"
  else if (trialUseSupportTillDate <= MAX_TRIAL_USE_SUPPORT_COUNT_MONTH)
    return "Experience support for disability welfare services (II)"
  return ""
}

export const EMPLOYMENT_STYLE = [
  { label: "Part time1", value: 1 },
  { label: "Part time2", value: 2 },
  { label: "Full time employee", value: 3 },
  { label: "Short time employee", value: 4 },
  { label: "Other", value: 5 },
]
export const transformData = (values, date, facility_id) => {
  const transformedAdditionalItems = []

  for (const key in values) {
    if (Array.isArray(values[key])) {
      values[key].forEach((item) => {
        if (item.additional_item_value !== "NO") {
          delete item.furigana
          delete item.user_info
          transformedAdditionalItems.push(item)
        }
      })
    }
  }

  return {
    facility_id: +facility_id || null,
    date: date,
    clear_previous_data: true,
    apply_only_to_specific_user: null,
    additional_items: transformedAdditionalItems,
  }
}

export const USER_RECEIPT_DISPLAY_SETTING_VALUES = {
  PRINT_COPY: "1",
  PRINT_ONLY_COPY: "2",
  CHANGE_RECIPIENT_NAME_TO_CHILD_NAME: "3",
  ADD_CHILD_NAME_TO_RECIPIENT_NAME: "4",
  PRINT_RECEIPT: "5",
  DISPLAY_SEAL_ON_RECEIPT_STAMP: "6",
  LEAVE_RECEIPT_DATE_BLANK: "7",
  CHANGE_ENVELOPE_LAYOUT: "8",
  DISPLAY_IN_PHONETIC_ORDER: "9",
  CHANGE_WORD_DISABILITY: "10",
  DISPLAY_SUBSIDY: "11",
  HIDE_0_ACTUAL_COST: "12",
}

export const USER_RECEIPT_DISPLAY_SETTINGS_FOR_STANDARD = [
  {
    label: "Print a copy",
    value: USER_RECEIPT_DISPLAY_SETTING_VALUES.PRINT_COPY,
  },
  {
    label: "Print copy only",
    value: USER_RECEIPT_DISPLAY_SETTING_VALUES.PRINT_ONLY_COPY,
  },

  {
    label: "Print a receipt",
    value: USER_RECEIPT_DISPLAY_SETTING_VALUES.PRINT_RECEIPT,
  },
  {
    label: "Leave the receipt date blank",
    value: USER_RECEIPT_DISPLAY_SETTING_VALUES.LEAVE_RECEIPT_DATE_BLANK,
  },
  {
    label: "Change to window envelope layout",
    value: USER_RECEIPT_DISPLAY_SETTING_VALUES.CHANGE_ENVELOPE_LAYOUT,
  },
  {
    label: "Display in phonetic order",
    value: USER_RECEIPT_DISPLAY_SETTING_VALUES.DISPLAY_IN_PHONETIC_ORDER,
  },
  {
    label: 'Change the word "disability" to "disability"',
    value: USER_RECEIPT_DISPLAY_SETTING_VALUES.CHANGE_WORD_DISABILITY,
  },
]

export const USER_RECEIPT_DISPLAY_SETTINGS_FOR_BREAKDOWN_USAGE = [
  {
    label: "Print a copy",
    value: USER_RECEIPT_DISPLAY_SETTING_VALUES.PRINT_COPY,
  },
  {
    label: "Print copy only",
    value: USER_RECEIPT_DISPLAY_SETTING_VALUES.PRINT_ONLY_COPY,
  },
  {
    label: "Change the recipient's name to the User's name",
    value:
      USER_RECEIPT_DISPLAY_SETTING_VALUES.CHANGE_RECIPIENT_NAME_TO_CHILD_NAME,
  },
  {
    label: "Add the User's  name to the recipient's name",
    value: USER_RECEIPT_DISPLAY_SETTING_VALUES.ADD_CHILD_NAME_TO_RECIPIENT_NAME,
  },
  {
    label: "Print a receipt",
    value: USER_RECEIPT_DISPLAY_SETTING_VALUES.PRINT_RECEIPT,
  },
  {
    label: "Leave the receipt date blank",
    value: USER_RECEIPT_DISPLAY_SETTING_VALUES.LEAVE_RECEIPT_DATE_BLANK,
  },
  {
    label:
      "Display the determined user charges and local government subsidy charges",
    value: USER_RECEIPT_DISPLAY_SETTING_VALUES.DISPLAY_SUBSIDY,
  },
  {
    label: "Change to window envelope layout",
    value: USER_RECEIPT_DISPLAY_SETTING_VALUES.CHANGE_ENVELOPE_LAYOUT,
  },
  {
    label: "Display in phonetic order",
    value: USER_RECEIPT_DISPLAY_SETTING_VALUES.DISPLAY_IN_PHONETIC_ORDER,
  },
  {
    label: "Hide 0 yen actual cost",
    value: USER_RECEIPT_DISPLAY_SETTING_VALUES.HIDE_0_ACTUAL_COST,
  },
  {
    label: 'Change the word "disability" to "disability"',
    value: USER_RECEIPT_DISPLAY_SETTING_VALUES.CHANGE_WORD_DISABILITY,
  },
]

export const PRINT_STYLE_VALUES = {
  STANDARD: "1",
  BREAKDOWN: "2",
  USAGE_DETAILS: "3",
}

export const PRINT_STYLE_OPTIONS = [
  {
    label: "Standard",
    value: PRINT_STYLE_VALUES.STANDARD,
  },
  {
    label: "With breakdown",
    value: PRINT_STYLE_VALUES.BREAKDOWN,
  },
  {
    label: "With usage details",
    value: PRINT_STYLE_VALUES.USAGE_DETAILS,
  },
]

export const PRINT_CONFIGURATION_VALUES = {
  ONE_PER_SHEET: "1",
  TWO_PER_SHEET: "2",
  THREE_PER_SHEET: "3",
}

export const PRINT_CONFIGURATION_OPTIONS = [
  {
    label: "Print one person on one sheet (A4)",
    value: PRINT_CONFIGURATION_VALUES.ONE_PER_SHEET,
  },
  {
    label: "Print two people on one sheet (A4)",
    value: PRINT_CONFIGURATION_VALUES.TWO_PER_SHEET,
  },
  {
    label: "Print for 3 people on 1 sheet (A4)",
    value: PRINT_CONFIGURATION_VALUES.THREE_PER_SHEET,
  },
]

export const CSV_OPTIONS: { [key: string]: CSVOption } = {
  CALCULATION: 0,
  INVOICE: 1,
  SERVICE_PROVISION: 2,
}

export const GetDecideServiceCodeByServiceType = (serviceType) => {
  const decideServiceCodes = [
    {
      decide_service_code_name: "Employment transition support service fee (I)",
      decide_service_code: "４３１０００",
      supported_services: [SERVICE_TYPE_VALUES.TRANSITION],
    },
    {
      decide_service_code_name:
        "Mentally disabled discharge support facility addition",
      decide_service_code: "４３０９０３",
      supported_services: [SERVICE_TYPE_VALUES.TRANSITION],
    },
    {
      decide_service_code_name: "Home life support service addition",
      decide_service_code: "４３０９３２",
      supported_services: [SERVICE_TYPE_VALUES.TRANSITION],
    },
    {
      decide_service_code_name: "Social life support special addition",
      decide_service_code: "４３０９２９",
      supported_services: [SERVICE_TYPE_VALUES.TRANSITION],
    },
    {
      decide_service_code_name:
        "Employment transition support service fee (II)",
      decide_service_code: "４４１０００",
      supported_services: [SERVICE_TYPE_VALUES.TRANSITION],
    },
    {
      decide_service_code_name: "Home life support service addition",
      decide_service_code: "４４０９３２",
      supported_services: [SERVICE_TYPE_VALUES.TRANSITION],
    },
    {
      decide_service_code_name: "Social life support special addition",
      decide_service_code: "４４０９２９",
      supported_services: [SERVICE_TYPE_VALUES.TRANSITION],
    },
    {
      decide_service_code_name:
        "Employment continuation support type A service fee (I)",
      decide_service_code: "４５１０００",
      supported_services: [SERVICE_TYPE_VALUES.TYPE_1],
    },
    {
      decide_service_code_name:
        "Employment continuation support type A service fee (II)",
      decide_service_code: "４５１０００",
      supported_services: [SERVICE_TYPE_VALUES.TYPE_1],
    },
    {
      decide_service_code_name: "Home life support service addition",
      decide_service_code: "４５０９３２",
      supported_services: [SERVICE_TYPE_VALUES.TYPE_1],
    },
    {
      decide_service_code_name: "Social life support special addition",
      decide_service_code: "４５０９２９",
      supported_services: [SERVICE_TYPE_VALUES.TYPE_1],
    },
    {
      decide_service_code_name:
        "Employment continuation support type B service fee (I)",
      decide_service_code: "４６１０００",
      supported_services: [SERVICE_TYPE_VALUES.TYPE_2],
    },
    {
      decide_service_code_name:
        "Employment continuation support type B service fee (II)",
      decide_service_code: "４６１０００",
      supported_services: [SERVICE_TYPE_VALUES.TYPE_2],
    },
    {
      decide_service_code_name:
        "Employment continuation support type B service fee (III)",
      decide_service_code: "４６１０００",
      supported_services: [SERVICE_TYPE_VALUES.TYPE_2],
    },
    {
      decide_service_code_name:
        "Employment continuation support type B service fee (IV)",
      decide_service_code: "４６１０００",
      supported_services: [SERVICE_TYPE_VALUES.TYPE_2],
    },
    {
      decide_service_code_name:
        "Employment continuation support type B service fee (Ⅴ)",
      decide_service_code: "４６１０００",
      supported_services: [SERVICE_TYPE_VALUES.TYPE_2],
    },
    {
      decide_service_code_name:
        "Employment continuation support type B service fee (Ⅵ)",
      decide_service_code: "４６１０００",
      supported_services: [SERVICE_TYPE_VALUES.TYPE_2],
    },
    {
      decide_service_code_name: "Home life support service addition",
      decide_service_code: "４６０９３２",
      supported_services: [SERVICE_TYPE_VALUES.TYPE_2],
    },
    {
      decide_service_code_name: "Social life support special addition",
      decide_service_code: "４６０９２９",
      supported_services: [SERVICE_TYPE_VALUES.TYPE_2],
    },
  ]

  return decideServiceCodes.filter((d) =>
    d.supported_services.includes(serviceType),
  )
}

export const PER_PAGE_PRINT_1_SERVICE_CONTENTS = 13

export const InitialReceiptProvisionLabel = "障害福祉サービスご利用分として"
